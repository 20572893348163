<template>
  <div>
    我的
  </div>
</template>

<script>
export default {
  name: 'user'
}
</script>

<style lang="less" scoped>

</style>
